var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.$hasPermissions(_vm.clientSession, ['CLIENT_REPORTS'], 1))?_c('div',[_c('vue-headful',{attrs:{"title":_vm.pageTitle}}),_c('div',{staticClass:"has-text-centered has-background-primary",staticStyle:{"margin-bottom":"20px"}},[_c('h1',{staticClass:"is-size-6 has-text-white",staticStyle:{"padding":"5px 0px"}},[_vm._v(_vm._s(_vm.pageheading.toLocaleUpperCase()))])]),_c('div',{staticStyle:{"max-width":"95%","margin":"auto"}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isLoading),expression:"isLoading"}]},[_c('Loading')],1),_c('div',{staticStyle:{"padding":"4px","height":"100%","width":"100%!important"}},[_c('div',{staticClass:"exception-filters-section"},[_c('div',{staticClass:"columns is-multiline is-desktop is-fullwidth"},[_c('div',{staticClass:"column is-full-mobile is-narrow"},[_c('b-field',{attrs:{"label":"Month End Date"}},[_c('b-datepicker',{staticStyle:{"max-width":"132px"},attrs:{"placeholder":"Select a date","editable":"","date-parser":_vm.customDateParser,"date-formatter":_vm.customDateFormatter},model:{value:(_vm.formattedDate),callback:function ($$v) {_vm.formattedDate=$$v},expression:"formattedDate"}})],1)],1),_c('div',{staticClass:"column is-full-mobile is-one-fifth-desktop"},[_c('b-field',{attrs:{"label":"Carrier"}},[_c('multiselect',{attrs:{"options":_vm.carriers,"multiple":true,"close-on-select":false,"clear-on-select":false,"preserve-search":true,"placeholder":"All Carriers","loading":_vm.carriersLoading,"label":"label","track-by":"value"},on:{"select":_vm.fetchClients,"remove":_vm.fetchClients},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var values = ref.values;
var search = ref.search;
var isOpen = ref.isOpen;
return [_c('span',{directives:[{name:"show",rawName:"v-show",value:(values.length > 0 && !isOpen),expression:"values.length > 0 && !isOpen"}],staticClass:"multiselect__single"},[_vm._v(_vm._s(values.length)+" carriers selected ")])]}}],null,false,3251735258),model:{value:(_vm.filters.selectedCarriersArray),callback:function ($$v) {_vm.$set(_vm.filters, "selectedCarriersArray", $$v)},expression:"filters.selectedCarriersArray"}},[_c('template',{slot:"clear"},[(_vm.filters.selectedCarriersArray.length)?_c('div',{staticClass:"multiselect__clear",on:{"mousedown":function($event){$event.preventDefault();$event.stopPropagation();return (function () { return (_vm.filters.selectedCarriersArray = []); }).apply(null, arguments)}}},[_c('i',{staticClass:"fas fa-times"})]):_vm._e()])],2)],1)],1),_c('div',{staticClass:"column is-full-mobile"},[_c('b-field',{attrs:{"label":"Client"}},[_c('multiselect',{attrs:{"options":_vm.clients,"multiple":true,"close-on-select":false,"clear-on-select":false,"loading":_vm.clientsLoading,"preserve-search":true,"placeholder":"All Clients","label":"label","track-by":"value"},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var values = ref.values;
var search = ref.search;
var isOpen = ref.isOpen;
return [_c('span',{directives:[{name:"show",rawName:"v-show",value:(values.length > 0 && !isOpen),expression:"values.length > 0 && !isOpen"}],staticClass:"multiselect__single"},[_vm._v(_vm._s(values.length)+" clients selected")])]}}],null,false,3652262911),model:{value:(_vm.filters.selectedClientsArray),callback:function ($$v) {_vm.$set(_vm.filters, "selectedClientsArray", $$v)},expression:"filters.selectedClientsArray"}},[_c('template',{slot:"clear"},[(_vm.filters.selectedClientsArray.length)?_c('div',{staticClass:"multiselect__clear",on:{"mousedown":function($event){$event.preventDefault();$event.stopPropagation();return (function () { return (_vm.filters.selectedClientsArray = []); }).apply(null, arguments)}}},[_c('i',{staticClass:"fas fa-times"})]):_vm._e()])],2)],1)],1),_c('div',{staticClass:"column is-full-mobile is-narrow"},[_c('b-field',{attrs:{"label":"Policy Values"}},[_c('b-select',{attrs:{"placeholder":"Select Policy Values"},model:{value:(_vm.filters.hasPolicyValues),callback:function ($$v) {_vm.$set(_vm.filters, "hasPolicyValues", $$v)},expression:"filters.hasPolicyValues"}},_vm._l((_vm.policyValues),function(value){return _c('option',{key:value.value,domProps:{"value":value.value}},[_vm._v(" "+_vm._s(value.text)+" ")])}),0)],1)],1),_c('div',{staticClass:"column is-full-mobile is-narrow is-flex is-align-items-bottom"},[_c('div',{staticClass:"control is-flex",staticStyle:{"align-items":"end","padding-bottom":"6px"}},[_c('button',{staticClass:"button is-accent",on:{"click":_vm.applyFilters}},[_vm._v("View Report")])])])])]),(!_vm.isLoading)?_c('Grid',{staticClass:"exception-grid header-1st-row-summary",style:({ maxHeight: 'none', height: 'auto' }),attrs:{"data-items":_vm.clientExceptions.value,"sortable":true,"resizable":true,"pageable":{
          pageSizes: [10, 20, 50, 100]
        },"columns":_vm.columns,"page-size":_vm.pagination.pageSize,"skip":_vm.pagination.skip,"total":_vm.clientExceptions.count,"sort":_vm.sort,"row-render":_vm.rowRenderFn},on:{"pagechange":_vm.onPageChange,"sortchange":_vm.sortChangeHandler},scopedSlots:_vm._u([{key:"CurrencyCell",fn:function(ref){
        var props = ref.props;
return [_c('td',{staticStyle:{"text-align":"right"}},[_vm._v(" "+_vm._s(_vm.formatCurrency(props.dataItem[props.field]))+" ")])]}},{key:"DateCell",fn:function(ref){
        var props = ref.props;
return [_c('td',{staticClass:"dateClass policyDateClass",staticStyle:{"text-align":"center"}},[_vm._v(" "+_vm._s(_vm.getFormattedDate(props.dataItem[props.field]))+" ")])]}},{key:"ParticipantsCell",fn:function(ref){
        var props = ref.props;
return [_c('td',{staticStyle:{"text-align":"left"}},[_vm._v(" "+_vm._s(props.dataItem['lastName'] + ", " + props.dataItem['firstName'])+" ")])]}}],null,false,1537873736)}):_vm._e()],1)])],1):_c('div',[_c('vue-headful',{attrs:{"title":_vm.pageTitle}}),_vm._m(0),_vm._m(1)],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"has-text-centered has-background-primary",staticStyle:{"margin-bottom":"20px"}},[_c('h1',{staticClass:"is-size-6 has-text-white",staticStyle:{"padding":"5px 0px"}},[_vm._v("No Permission")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"has-text-centered "},[_c('b',[_vm._v("You Lack The Permissions Required To View This Page")])])}]

export { render, staticRenderFns }